<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/bg-1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Solutia ta hardware</h1>
          <h4 class="title">TREND · IMPORT · EXPORT</h4>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Cine suntem?</h2>
            <h5 class="description">
              Având o istorie de aproape două decenii, o experienţă vastă atât la nivel
              naţional, cât şi internaţional, spiritul mereu tânăr, adaptabili şi adaptaţi
              continuu realităţilor de piaţă, ne mândrim cu o structură vastă, aptă să
              acopere şi să diversifice nevoile consumatorilor de IT.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/home-oracle.jpg')"
              >
                <!-- First image on the left side -->
<!--                <p class="blockquote blockquote-primary">-->
<!--                  "You miss 100% of the shots you don't take -Wayne Gretzky"-->
<!--                  <br />-->
<!--                  <br />-->
<!--                  <small>-Michael Scott</small>-->
<!--                </p>-->
              </div>
              <!-- Second image on the left side of the article -->
              <div
                  class="image-container"
                  style="background-image: url('img/home-banking.jpg')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                  class="image-container image-right"
                  style="background-image: url('img/home-hp.jpg')"
              ></div>
              <h3>
                Trend este una dintre cele mai importante companii româneşti de comercializare de
                produse şi servicii IT.
              </h3>
              <p>
                Având o istorie de aproape două decenii, o experienţă vastă atât la nivel naţional, cât şi internaţional, spiritul mereu tânăr, adaptabili şi adaptaţi continuu realităţilor de piaţă, ne mândrim cu o structură vastă, aptă să acopere şi să diversifice nevoile consumatorilor de IT.
              </p>
              <p>
                Prin toate acestea, Trend promovează calitatea produselor, satisfacţia clienţilor, concurenţa şi, nu în ultimul rând, transparenţa serviciilor sale.
              </p>
              <p>
                Capitalul în întregime privat ne-a asigurat de-a lungul anilor flexibilitate managerială, fapt ce a permis dezvoltarea de relaţii constructive cu parteneri cunoscuţi la nivel mondial, dintre care îi amintim aici pe cei mai importanţi: Hewlett Packard, Microsoft, Lenovo, Oracle, AVG şi iiView.
              </p>
              <p>
                Atât relaţia cu aceste firme, cât şi munca de intermediere dintre ei şi clienţii noştri ne-au asigurat o bază solidă în cunoaşterea nevoilor clienţilor şi, mai important, a modului cum pot fi gestionate aceste nevoi.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Partenerii nostri</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-3 ml-auto mr-auto">
              <div class="team-player">
                <img
                    src="img/hp2.png"
                    alt="Thumbnail Image"
                    class="img-fluid"
                />
                <h4 class="title">Hewlett-Packard</h4>
              </div>
            </div>
            <div class="col-md-3 ml-auto mr-auto">
              <div class="team-player">
                <img
                    src="img/oracle.png"
                    alt="Thumbnail Image"
                    class="img-fluid"
                />
                <h4 class="title">Oracle</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hpe-landing" v-show="hpemodal">
      <div class="hpe-header" @click="hpemodal = !hpemodal">
        Click aici pentru a continua mai departe catre site-ul TREND
      </div>
      <div class="hpe-content">
        <iframe src="https://wcs-hpeproliantgen11.atworkweb.com" style="margin-top:60px;height: calc(100vh - 60px); width: 100%; border: none;"></iframe>
      </div>
    </div>

  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'home',
  bodyClass: 'home-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      hpemodal: true
    };
  }
};
</script>
<style lang="scss">
  .hpe-landing {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999999;
    height: 100%;
    width: 100%;
    .hpe-header {
      background: #12948B;
      color: #fff;
      cursor: pointer;
      font-size: 1.5rem;
      padding: 0.75rem;
      text-align: center;
      width: 100%;
      position: fixed;
    }
    .hpe-content {
      height: 100%;
      width: 100%;
    }
    .hpe-bottom {
      height: 10rem;
      width: 100%;
    }
  }
  .vue-friendly-iframe {
    iframe {
      height: 100vh;
      width:100%;
      border:none;
    }
  }
</style>
