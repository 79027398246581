<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">

      <nav>
        <ul>
          <li>
            <a href="https://trend.ro">
              trend · import · export
            </a>
          </li>
          <li>
            <a href="tel:0725347474"><i class="fas fa-phone"></i></a>
          </li>
          <li>
            <a href="mailto:sales@trend.ro"><i class="fas fa-envelope"></i></a>
          </li>
          <li>
            <router-link to="/despre-noi">
              DESPRE NOI
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}
      </div>
    </div>
  </footer>
</template>
<script>
import {Button, FormGroupInput} from "@/components";

export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style lang="scss">
.footer {
  .section-contact-us {
    h4, input  {
      color: #001A18 !important;
    }
    .input-group-text {
      color: #555555 !important;
    }
    .info-box {
      i, a {
        color: #12948B !important;
      }
    }
    input, i {
      border: 1px solid #E3E3E3 !important;
    }
    input {
      border-left: none !important;;
    }
    i {
      border-right: none !important;
    }
    textarea {
      border-bottom: 1px solid #E3E3E3 !important;
      border-radius: 0 !important;
    }
    .form-control::placeholder, .el-date-picker .el-input .el-input__inner::placeholder, .form-group .el-input__inner::placeholder {
      color: #888888 !important;

      opacity: 0.8;

      filter: alpha(opacity=80);

    }
    .form-control, .el-date-picker .el-input .el-input__inner, .form-group .el-input__inner {
      background-color: transparent;
      border: 1px solid #E3E3E3;
      border-radius: 30px;
      color: #2c2c2c;
      line-height: normal;
      font-size: 0.8571em;
      height: 100%;
      transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
      box-shadow: none;
    }
    .form-control:focus, .el-date-picker .el-input .el-input__inner:focus, .form-group .el-input__inner:focus {
      border-bottom: 1px solid #12948B !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 !important;
      color: #2c2c2c;
    }
    //textarea {
    //  border-bottom: 1px solid #E3E3E3 !important;
    //}
  }
}
</style>
