<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    :class="{dark: isDark}"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img v-if="isDark"
            src="/img/logo-dark.png"
            alt="Logo Trend.ro"
            class="logo"
        />
        <img v-else
            src="/img/logo.png"
            alt="Logo Trend.ro"
            class="logo"
        />
      </router-link>
<!--      <el-popover-->
<!--        ref="popover1"-->
<!--        popper-class="popover"-->
<!--        placement="bottom"-->
<!--        width="200"-->
<!--        trigger="hover"-->
<!--      >-->
<!--        <div class="popover-body">-->
<!--          Designed by Invision. Coded by Creative Tim-->
<!--        </div>-->
<!--      </el-popover>-->
    </template>
    <template slot="navbar-menu">
      <drop-down
          tag="li"
          title="Servicii Oferite"
          icon="now-ui-icons design_app"
          class="nav-item"
      >
        <nav-link>
          <i class="now-ui-icons business_chart-pie-36"></i> Centru Service Autorizat
        </nav-link>
        <nav-link>
          <i class="now-ui-icons design_bullet-list-67"></i> Servicii Data Center
        </nav-link>
<!--        <a-->
<!--            href="https://demos.creative-tim.com/vue-now-ui-kit/documentation"-->
<!--            target="_blank"-->
<!--            class="dropdown-item"-->
<!--        >-->

<!--        </a>-->
      </drop-down>
      <drop-down
          tag="li"
          title="Parteneri"
          icon="now-ui-icons design_app"
          class="nav-item"
      >
        <nav-link to="/parteneri/hpe">
          <i class="now-ui-icons design_bullet-list-67"></i> Hewlett Packard Enterprise
        </nav-link>
      </drop-down>
<!--      <drop-down-->
<!--              tag="li"-->
<!--              title="Despre Noi"-->
<!--              icon="now-ui-icons travel_info"-->
<!--              class="nav-item"-->
<!--      >-->
<!--        <nav-link to="/despre-noi">-->
<!--          <i class="now-ui-icons business_badge"></i> Context-->
<!--        </nav-link>-->
<!--        <nav-link to="/despre-noi/filozofie">-->
<!--          <i class="now-ui-icons users_circle-08"></i> Filozofie-->
<!--        </nav-link>-->
<!--        <nav-link to="/despre-noi/istoric">-->
<!--          <i class="now-ui-icons users_single-02"></i> Istoric-->
<!--        </nav-link>-->
<!--        <nav-link to="/despre-noi/misiune-viziune">-->
<!--          <i class="now-ui-icons users_single-02"></i> Misiune si Viziune-->
<!--        </nav-link>-->
<!--      </drop-down>-->
      <li class="nav-item">
        <router-link class="nav-link" to="/despre-noi">
          <i class="now-ui-icons  travel_info"></i>
          <p>Despre noi</p>
        </router-link>
      </li>

<!--      <li class="nav-item">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          rel="tooltip"-->
<!--          title="Follow us on Twitter"-->
<!--          data-placement="bottom"-->
<!--          href="https://twitter.com/CreativeTim"-->
<!--          target="_blank"-->
<!--        >-->
<!--          <i class="fab fa-twitter"></i>-->
<!--          <p class="d-lg-none d-xl-none">Twitter</p>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          rel="tooltip"-->
<!--          title="Like us on Facebook"-->
<!--          data-placement="bottom"-->
<!--          href="https://www.facebook.com/CreativeTim"-->
<!--          target="_blank"-->
<!--        >-->
<!--          <i class="fab fa-facebook-square"></i>-->
<!--          <p class="d-lg-none d-xl-none">Facebook</p>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          rel="tooltip"-->
<!--          title="Follow us on Instagram"-->
<!--          data-placement="bottom"-->
<!--          href="https://www.instagram.com/CreativeTimOfficial"-->
<!--          target="_blank"-->
<!--        >-->
<!--          <i class="fab fa-instagram"></i>-->
<!--          <p class="d-lg-none d-xl-none">Instagram</p>-->
<!--        </a>-->
<!--      </li>-->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
    isDark: Boolean
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped lang="scss">

</style>
