import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Home from './pages/Home.vue';
import Login from './pages/Login.vue';
import DespreNoi from './pages/DespreNoi.vue';
import DespreFilozofie from './pages/DespreFilozofie.vue';
import DespreIstoric from './pages/DespreIstoric.vue';
import DespreMisiune from './pages/DespreMisiune.vue';
import HPEnterprise from './pages/HPEnterprise.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainContact from './layout/MainContact.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      components: { default: Home, header: MainNavbar, contact: MainContact, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
    {
      path: '/index',
      name: 'index',
      components: { default: Index, header: MainNavbar, contact: MainContact, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/despre-noi',
      name: 'despre-noi',
      components: { default: DespreNoi, header: MainNavbar, contact: MainContact, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
    {
      path: '/despre-noi/filozofie',
      name: 'despre-filozofie',
      components: { default: DespreFilozofie, header: MainNavbar, contact: MainContact, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
    {
      path: '/despre-noi/istoric',
      name: 'despre-istoric',
      components: { default: DespreIstoric, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
    {
      path: '/despre-noi/misiune-viziune',
      name: 'despre-misiune-si-viziune',
      components: { default: DespreMisiune, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
    {
      path: '/parteneri/hpe',
      name: 'parteneri-hpe',
      components: { default: HPEnterprise, header: MainNavbar, contact: MainContact, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, isDark: true },
        footer: { backgroundColor: 'darkBlue' }
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
