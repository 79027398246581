<template>
  <div class="section section-contact-us text-center">
    <div class="container">
      <!--        <p class="description">Puteti lua legatura .</p>-->
      <div class="row">
        <div class="col-lg-6 col-sm-12 text-left ml-auto mr-auto info-box">
          <h4 class="title text-center">Informatii</h4>
          <br/>
          <br/>
          <a href="tel:0725347474"><i class="fas fa-phone-square"></i> 0725 34 74 74</a>
          <br/>
          <br/>
          <a href="mailto:sales@trend.ro"><i class="fas fa-envelope-square"></i> sales@trend.ro</a>
          <br/>
          <br/>
          <p> <i class="fas fa-map-marker-alt"></i> Str. Popa Savu, nr. 77, sector 1, 011432 </p>
          <p> <i class="fas fa-building"></i> București </p>
        </div>
        <div class="col-lg-6 col-sm-12 text-center ml-auto mr-auto col-md-6 contact-box">
          <h4 class="title">Formular contact</h4>
          <fg-input
              class="input-lg"
              placeholder="Numele dvs..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
          >
          </fg-input>
          <fg-input
              class="input-lg"
              placeholder="Adresa e-mail..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
          >
          </fg-input>
          <div class="textarea-container">
              <textarea
                  class="form-control"
                  name="name"
                  rows="4"
                  cols="80"
                  v-model="form.message"
                  placeholder="Mesajul dvs..."
              ></textarea>
          </div>
          <div class="send-button">
            <n-button type="primary" round block size="lg">Trimite mesaj</n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Button, FormGroupInput} from "@/components";

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      year: new Date().getFullYear()
    };
  }
};
</script>
<style lang="scss">
.section-contact-us {
  border: 1rem solid #001A18;
  border-bottom: none;
}
.info-box {
  border-right: 1px solid #001A18;
}
.contact-box {
  padding-left: 2rem !important;
}
</style>