<template>
<div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg-1.jpg')"
      >
      </parallax>
      <div class="container">
<!--        <div class="photo-container">-->
<!--          <img src="img/ryan.jpg" alt="" />-->
<!--        </div>-->
        <h3 class="title">trend · import · export</h3>
        <p class="category">una dintre cele mai importante companii româneşti de comercializare de
          produse şi servicii IT</p>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="button-container">
          <a href="#button" class="btn btn-primary btn-round btn-lg">Follow</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
        <h3 class="title">Despre noi</h3>
        <h5 class="quote">
          <h4 class="blockquote blockquote-primary">
            Din perspectiva noastră, există patru entităţi diferite faţă de care Trend are responsabilităţi şi interese: clienţii, partenerii, compania ca atare şi mediul social şi instituţional în care ne desfăşurăm activitatea.
          </h4>
        </h5>
          <div class="mr-auto">
            <!-- Tabs with Background on Card -->
            <div class="card">
              <tabs
                  centered
                  type="neutral"
                  tab-nav-wrapper-classes="card-header"
                  tab-content-classes="card-body text-center"
                  data-background-color="orange"
              >
                <tab-pane>
                  <template slot="label">
                    <i class="now-ui-icons objects_umbrella-13"></i> Clienţi
                  </template>
                  <h4>
                    Datorită politicii de marketing agresive şi active adoptată de companie şi datorită interesului crescut pentru diversificarea segmentelor de piaţă (cum ar fi interesul pentru banking, SMB etc.), numărul clienţilor noştri a crescut şi s-a diversificat la rândul lui, în special în rândul companiilor tinere.
                  </h4>
                  <h4>
                  Am implementat aplicaţia CRM (Managementul Relaţiei cu Clienţii) ce updatează continuu datele clienţilor (adrese, date de contact) şi ajută la coordonarea proiectelor iniţiate cu aceştia până la finalizare.
                  </h4>
                  <h4>
                    De asemenea, am implementat aplicaţia DMS (Sistemul de Management al Documentelor) care este vizibilă tuturor angajaţilor şi permite realizarea online a modificărilor necesare.
                  </h4>
                </tab-pane>
                <tab-pane>
                  <template slot="label">
                    <i class="now-ui-icons shopping_cart-simple"></i> Parteneri
                  </template>
                  <h4>
                     În scurtă vreme de la încorporarea sa, Trend a devenit primul partener român la Hewlett Packard.
                  </h4>
                  <h4>
                    Acest parteneriat s-a dezvoltat rapid, datorită comunicării eficiente şi a încrederii reciproce, astfel că în 1993, Trend a devenit primul distribuitor autorizat de echipamente HP în România. În continuare, activitatea companiei s-a diversificat, adăugându-şi în lista de parteneri branduri de renume, cum ar fi Cisco Systems, Linksys, Microsoft, Lenovo, Oracle, Texas Memory Systems, AVG, Hitachi şi iiView.
                  </h4>
                  <h4>
                    Relaţiile cu aceste companii sunt bazate pe responsabilitate, respect şi interes reciproc.
                  </h4>
                </tab-pane>
                <tab-pane>
                  <template slot="label">
                    <i class="now-ui-icons shopping_shop"></i> Companie
                  </template>
                  <h4>
                    Pentru a putea asigura un nivel înalt de profesionalism şi corectitudine, Trend investeşte în angajaţii săi încurajându-i şi susţinându-i să participe la diferite tipuri de cursuri: de vânzări, de import de produse, de legislaţie a taxelor şi impozitelor şi de legislaţie generală.
                  </h4>
                  <h4>
                    Astfel, specialiştii IT crescuţi de Trend susţin dezvoltarea adecvată a companiei putându-şi realiza simultan propriile scopuri şi obiective.
                  </h4>
                </tab-pane>
                <tab-pane>
                  <template slot="label">
                    <i class="now-ui-icons ui-2_settings-90"></i> Mediu
                  </template>
                  <h4>
                    Trend investeşte şi este interesată să-şi atingă scopurile prin acţiuni responsabile, ce iau în considerare impactul pe termen lung pe care îl are compania în aria unde deţine influenţă.
                  </h4>
                  <h4>
                  În acelaşi mod în care acţionăm responsabil în relaţia cu clienţii, partenerii şi angajaţii noştri, ştim că este de datoria noastră să planificăm şi să gândim strategii şi programe în concordanţă cu interesele comunităţii în care ne desfăşurăm activitatea spre a obţine beneficii de interes comun.
                  </h4>
                </tab-pane>
              </tabs>
            </div>
            <!-- End Tabs on plain Card -->
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from '@/components';

export default {
  name: 'despre-misiune-viziune',
  bodyClass: 'despre-misiune-viziune',
  components: {
    Tabs,
    TabPane
  }
};
</script>
<style></style>
