<template>
  <div>
    <div class="page-header" filter-color="green">
      <parallax
          class="page-header-image hpe"
          style="background-image:url('/img/hpe-bg.png')"
      >
      </parallax>
      <div class="container">
        <div class="partner-logo">
          <img src="/img/hpe.png" alt="" />
        </div>
        <h3 class="title dark">TREND · IMPORT · EXPORT</h3>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <!--        <h3 class="title">Servicii HP Enteprise</h3>-->
        <div class="mr-auto">
          <!-- Tabs with Background on Card -->
          <div class="card">
            <tabs
                :tabs-name="'Servicii HP Enterprise'"
                type="neutral"
                tab-nav-wrapper-classes="card-header"
                tab-content-classes="card-body text-center"
                data-background-color="darkBlue"
            >
              <tab-pane>
                <template slot="label">
                  Server
                </template>
                <h4>
                  Datorită politicii de marketing agresive şi active adoptată de companie şi datorită interesului crescut pentru diversificarea segmentelor de piaţă (cum ar fi interesul pentru banking, SMB etc.), numărul clienţilor noştri a crescut şi s-a diversificat la rândul lui, în special în rândul companiilor tinere.
                </h4>
                <h4>
                  Am implementat aplicaţia CRM (Managementul Relaţiei cu Clienţii) ce updatează continuu datele clienţilor (adrese, date de contact) şi ajută la coordonarea proiectelor iniţiate cu aceştia până la finalizare.
                </h4>
                <h4>
                  De asemenea, am implementat aplicaţia DMS (Sistemul de Management al Documentelor) care este vizibilă tuturor angajaţilor şi permite realizarea online a modificărilor necesare.
                </h4>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  Storage
                </template>
                <h3>
                  <b>Soluții de Stocare a Datelor Concepute Special pentru Dvs.</b>
                </h3>
                <h4>
                  Accelerăm modernizarea axată pe date, simplificând în mod radical gestionarea datelor cu o soluție cuprinzătoare pentru stocarea, gestionarea și protecția datelor în întregul mediu hibrid cloud.
                </h4>
                <h4>
                  Transformați-vă cu stocare enterprise flexibilă și intuitivă. Simplificați gestionarea datelor de la margine la cloud și transformați afacerea mai rapid cu ajutorul HPE Alletra și a unei experiențe operaționale în cloud concepute pentru a gestiona toate sarcinile dvs. de lucru.
                </h4>
                <h4>
                  Modernizați protecția datelor. Asigurați-vă afacerea împotriva ransomware-ului, recuperați-vă de la orice perturbare și protejați sarcinile de lucru fără efort pe întregul mediu hibrid cloud. Simplificați stocarea datelor cu ajutorul unui serviciu de tip self-service, la cerere, în cloud.
                </h4>
                <h4>
                  Revigorați fluxurile de lucru de la margine la cloud fără compromisuri. Soluțiile de stocare HPE optimizează furnizarea, protecția și mobilitatea hibridă cloud pentru mașinile virtuale (VM) ale dvs."
                </h4>
                <h4>
                  Alletra
                </h4>
                <p>
                  Este momentul să eliminați complexitatea și izolarea întâlnite în mediile tradiționale de cloud hibrid. HPE Alletra este o infrastructură nativă pentru date în cloud, alimentată de platforma HPE GreenLake. Împreună, acestea vă oferă o experiență operațională și de consum bazată pe cloud, oriunde se află datele dvs.

                  Conceput pentru toate tipurile de aplicații, de la cele tradiționale la cele moderne, HPE Alletra oferă o gamă de sisteme optimizate pentru sarcini de lucru pentru a furniza flexibilitate arhitecturală fără complexitățile managementului tradițional al stocării. Prin facilitarea mobilității datelor în diferite medii de cloud, HPE Alletra deblochează adevăratul potențial al cloudului hibrid al dvs."
                </p>
                <h5>SimpliVity</h5>
                <p>Închipuiți-vă o arhitectură hipercumulată care depășește limitele definirii software și devine condusă de inteligență artificială. Acest sistem avansat nu numai că se administrează singur, ci și optimizează și vindecă infrastructura autonom. Prin integrarea capacităților de analiză predictivă bazată pe învățare automată de la HPE InfoSight în platforma noastră hipercumulată SimpliVity, am inaugurat o eră caracterizată de interacțiune inteligentă între om și calculator (HCI)."</p>

                <h5>MSA</h5>
                <p>Îmbrățișați cea mai recentă versiune a lui HPE MSA, Gen6, care continuă să-și consolideze reputația ca fiind cel mai bine vândut sistem de stocare de nivel de intrare al HPE timp de peste un deceniu. Cu o combinație unică de simplitate, viteză, accesibilitate, fiabilitate de nivel enterprise și integrare ușoară a backup-ului în cloud, HPE MSA Gen6 se remarcă ca fiind alegerea ideală pentru întreprinderile mici și mijlocii din toată lumea."

                <h5>Suporturi de Stocare</h5>
                <p>
                  Organizațiile se întorc din nou la stocarea economică, scalabilă și cu adevărat offline pe bandă Linear Tape-Open (LTO) pentru a proteja și păstra datele esențiale, care sunt accesate rar, pe o perioadă lungă de timp. Liderii IT pot răspunde tuturor cerințelor lor de retenție și conformitate cu ajutorul soluțiilor de stocare pe bandă HPE StoreEver, cunoscute pentru costurile totale de proprietate extrem de scăzute și pentru protecția datelor prin separare aeriană împotriva ransomware-ului și a coruperii datelor.
                </p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  GreenLake
                </template>
                <h4>
                  Ca model de IT bazat pe consum, Hewlett Packard Enterprise's (HPE) GreenLake permite agilitatea și economiile unei experiențe de cloud public, în timp ce oferă beneficiile securității și performanței infrastructurii IT on-premises.
                </h4>
                <h4>
                  Echipa TREND IMPORT EXPORT - HPE GreenLake este aici pentru a vă ajuta pe dumneavoastră și pe clienții dvs. să avansați rapid, să vă dezvoltați afacerea și, cel mai important, să economisiți costuri în timp ce vă îmbunătățiți rentabilitatea. Oferim consultanță strategică privind strategiile de IT pentru cloud hibrid, proiectăm și construim soluții IT de top și vă ajutăm să operați și să consumați IT ca serviciu. Echipa noastră este pregătită să vă pregătească pentru ceea ce urmează.
                </h4>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  Aruba
                </template>
                <h3><b>Descoperiți vârful soluțiilor de management de rețea</b></h3>
                <h4>
                  Rețelistică HPE Aruba se află în fruntea industriei, oferind soluții de rețelistică prin cablu și wireless de ultimă generație, securitate de top și managementul rețelei în cloud. Aruba câștigă constant aprecieri de la Gartner pentru leadership-ul său în inovația WLAN și SD-WAN și expertiză.
                </h4>
                <h4>
                  Aruba a câștigat încrederea unor clienți majori din domeniul întreprinderilor, retailului, sănătății și sectorului public, inclusiv nume proeminente precum Home Depot, Walt Disney World, Universitatea Texas A&M, Pentagonul și Armata SUA, făcând-o un brand recunoscut și de încredere în domeniile rețelistică și securitate.
                </h4>
              </tab-pane>
            </tabs>
          </div>
          <!-- End Tabs on plain Card -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from '@/components';

export default {
  name: 'parteneri-hpe',
  bodyClass: 'about-page',
  components: {
    Tabs,
    TabPane
  }
};
</script>
<style lang="scss">
.partner-logo {
  text-align: left;
  margin-top: 3rem;
  img {
    height: 100px;
  }
}
.title.dark {
  color: #001A18;
}
</style>
